.App {
  text-align: center;
}

.splash-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, #87CEEB, #E0F6FF);
  overflow: hidden;
}

.three-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensure the 3D scene stays behind the content */
}

.social-links {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
  border: 2px solid #4169E1; /* Add this line for the blue border */
}

.social-links a:hover {
  background-color: rgba(0, 0, 0, 0.4);
  transform: translateY(-2px);
  border-color: #4169E1; /* Change border color on hover */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pause-menu {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  border: 2px solid #4169E1; /* Add this line for the blue border */
}

.about-section {
  position: static;
  top: auto;
  left: auto;
  right: auto;
  max-width: none;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  max-width: 300px;
  text-align: left;
  transition: all 0.3s ease;
  border: 2px solid #4169E1; /* Add this line for the blue border */
}

.about-section h2 {
  margin: 0;
  cursor: pointer;
  user-select: none;
}

.about-section.expanded {
  background-color: rgba(0, 0, 0, 0.9);
}

.about-content {
  margin-top: 15px;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.about-section.expanded .about-content {
  opacity: 1;
  max-height: 500px;
}

.resume-section {
  position: static;
  top: auto;
  left: auto;
  right: auto;
  max-width: none;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  max-width: 300px;
  text-align: left;
  transition: all 0.3s ease;
  border: 2px solid #4169E1;
}

.resume-section h2 {
  margin: 0;
  cursor: pointer;
  user-select: none;
}

.resume-section.expanded {
  background-color: rgba(0, 0, 0, 0.9);
  max-width: 400px;
}

.resume-content {
  margin-top: 15px;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.resume-section.expanded .resume-content {
  opacity: 1;
  max-height: 1000px;
}

.resume-content h3 {
  margin-top: 15px;
  margin-bottom: 5px;
}

.resume-content h4 {
  margin-top: 10px;
  margin-bottom: 5px;
}

.resume-content ul {
  margin-top: 5px;
  padding-left: 20px;
}

.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px 0;
  z-index: 1001;
}

.nav-bar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.nav-bar li {
  margin: 0 15px;
}

.nav-bar button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 5px 10px;
}

.nav-bar button:hover {
  color: #4169E1;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

/* Adjust the top position of AboutSection and ResumeSection to accommodate the NavBar */
.about-section,
.resume-section {
  top: 60px;
}

.nav-bar button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-bar button:hover {
  color: #4169E1;
}

.content-section {
  position: absolute;
  top: 60px;
  left: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  transition: all 0.3s ease;
  border: 2px solid #4169E1;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  margin-bottom: 30px;
}

.content-section h2 {
  color: #4169E1;
  margin-bottom: 20px;
  font-size: 24px;
}

.content-section h3 {
  color: #87CEEB;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 20px;
}

.content-section ul {
  list-style-type: none;
  padding-left: 20px;
}

.content-section li {
  margin-bottom: 5px;
}

.projects-section,
.contact-section {
  max-width: 800px;
  margin: 0 auto;
}

.projects-content,
.contact-content {
  margin-top: 20px;
}

.projects-content h3,
.contact-content h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #4169E1;
}

.projects-content ul,
.contact-content ul {
  list-style-type: none;
  padding-left: 0;
}

.projects-content li,
.contact-content li {
  margin-bottom: 10px;
}

.contact-content a {
  color: #4169E1;
  text-decoration: none;
}

.contact-content a:hover {
  text-decoration: underline;
}

.about-content,
.resume-content,
.projects-content,
.contact-content {
  margin-top: 20px;
}

.content-display {
  position: fixed;
  top: 60px; /* Adjust this value to position below the NavBar */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 30px;
  border-radius: 10px;
  width: 80%;
  max-width: 1000px;
  height: calc(100vh - 120px); /* Adjust height to leave space for NavBar and some margin */
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.content-section {
  display: block;
  opacity: 1;
  max-height: none;
  overflow: visible;
}

.about-content, .resume-content, .projects-content, .contact-content {
  display: block;
  opacity: 1;
  max-height: none;
  overflow: visible;
}

.about-section h2, .resume-section h2 {
  margin-bottom: 15px;
}

.resume-content h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.resume-content ul {
  padding-left: 20px;
}

.copied-feedback {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  z-index: 2000;
  animation: fadeInOut 1.5s ease-in-out;
  pointer-events: none;
  white-space: nowrap;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  20%, 80% { opacity: 1; }
}