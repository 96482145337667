:root {
  --primary-color: #4169E1;
  --text-color: #ffffff;
  --background-color: rgba(0, 0, 0, 0.7);
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}

.splash-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.three-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.nav-bar {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 30px;
  margin: 20px auto;
  width: fit-content;
}

.nav-item {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 16px;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-item.active {
  background-color: var(--primary-color);
  color: #ffffff;
}

.content-display {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 30px;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 30px;
  margin: 0 auto;
  width: fit-content;
}

.social-links a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
  padding: 5px 10px;
}

.social-links a:hover {
  color: var(--primary-color);
}

.fade-enter {
  opacity: 0;
  transform: translate(-50%, -40%);
}

.fade-enter-active {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: translate(-50%, -50%);
}

.fade-exit-active {
  opacity: 0;
  transform: translate(-50%, -60%);
  transition: opacity 300ms, transform 300ms;
}

.about-section {
  max-width: 800px;
  margin: 0 auto;
}

.about-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px; /* Reduce the gap between grid items */
}

.about-intro {
  grid-column: 1 / -1;
  text-align: center;
  margin-bottom: 15px; /* Add some space below the intro */
}

.about-intro h3 {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.about-details, .about-background, .about-passion {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px; /* Reduce padding */
  border-radius: 8px; /* Slightly reduce border radius */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-details:hover, .about-background:hover, .about-passion:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.about-section h4 {
  color: var(--primary-color);
  margin-bottom: 10px;
}

.about-details ul {
  list-style-type: none;
  padding-left: 0;
}

.about-details li {
  margin-bottom: 5px;
  position: relative;
  padding-left: 20px;
}

.about-details li::before {
  content: '▹';
  position: absolute;
  left: 0;
  color: var(--primary-color);
}

.about-background, .about-passion {
  grid-column: 1 / -1;
}

/* Add this new rule to create some space between sections */
.about-details, .about-background, .about-passion {
  margin-bottom: 15px;
}

/* Adjust the last item to remove the bottom margin */
.about-passion {
  margin-bottom: 0;
}

.content-section h3 {
  color: #FFD700; /* Changed from #87CEEB to #FFD700 (Gold) */
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 50px;
}

.projects-content {
  display: grid;
  gap: 30px; /* Increased from 15px */
}

.project-item {
  background-color: rgba(65, 105, 225, 0.1);
  border-radius: 8px;
  padding: 15px;
  transition: all 0.3s ease;
  border: 1px solid rgba(65, 105, 225, 0.3);
  margin-bottom: 5px; /* Added to ensure no overlap */
}

.project-item h3 {
  color: var(--primary-color);
  margin-top: 0;
  margin-bottom: 8px; /* Increased from 5px */
  font-size: 1.2em; /* Slightly increased from 1.1em */
}

.project-item p {
  font-style: italic;
  margin-top: 0;
  margin-bottom: 10px; /* Increased from 8px */
  font-size: 0.9em;
}

.project-item ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 10px; /* Increased from 5px */
  margin-bottom: 0;
}

.project-item li {
  margin-bottom: 6px; /* Increased from 4px */
  position: relative;
  padding-left: 20px; /* Increased from 15px */
  font-size: 0.95em;
}

.project-item li:last-child {
  margin-bottom: 0;
}

.project-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(65, 105, 225, 0.2); /* Increased shadow */
}

.project-item h3, .resume-item h3 {
  color: var(--primary-color);
  margin-bottom: 8px; /* Reduced from 10px */
  font-size: 1.1em; /* Slightly smaller font size */
}

.project-item p, .resume-item p {
  font-style: italic;
  margin-bottom: 12px; /* Reduced from 15px */
  font-size: 0.9em; /* Slightly smaller font size */
}

.project-item ul, .resume-item ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 8px; /* Added margin-top */
}

.project-item li, .resume-item li {
  margin-bottom: 6px; /* Reduced from 8px */
  position: relative;
  padding-left: 20px; /* Reduced from 15px */
  font-size: 0.95em; /* Slightly smaller font size */
}

.project-item li::before, .resume-item li::before {
  content: '▹';
  position: absolute;
  left: 0;
  color: var(--primary-color);
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skill-tag {
  background-color: rgba(65, 105, 225, 0.2);
  color: var(--primary-color);
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em;
}

.resume-section h4 {
  color: #FFD700;
  margin-top: 15px;
  margin-bottom: 5px;
}

.download-resume-btn {
  background-color: #4169E1;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.download-resume-btn:hover {
  background-color: #3151A8;
}
